<template>
	<div
		class="full-height flex-column"
	>
		<div
			class="mt-10 pa-10 bg-white full-height"
		>
			<div
				v-show="user.agency_type == 'A001001'"
			>
				<select
					v-model="item_search.seller_id"
					class="input-box"
					@change="getData"
				>
					<option value="">상점을 선택하세요</option>
					<option
						v-for="(shop, s_index) in agency_list"
						:key="'shop_' + s_index"
						:value="shop.agency_id"
					>{{ shop.agency_name}}</option>
				</select>
			</div>
			<table class="table mt-10">
				<thead>
					<tr>
						<th colspan="2">구분</th>
						<th>명칭</th>
						<th>PG</th>
						<th>수수료</th>
						<th>사용여부</th>
					</tr>
				</thead>
				<template
					v-for="item1 in items"
				>
				<tbody
					:key="'body_' + item1.site_id"
				>
					<template
						v-for="(item, index) in item1.items_point"
					>
						<tr
							:key="'item_'+index"
						>
							<td>{{ item1.site_name }}</td>
							<td>{{ item.pg_type_name }}</td>
							<td>{{ item.name }}</td>
							<td>{{ item.pg_name }}</td>
							<td><input v-model="item.total_rate" type="number" class="input-box-inline" :rules="rules.max(item, 'per', 4)"/> %</td>
							<td>
								<v-icon
									class="pa-5 "
									:class="item.pg_status == 1 ? 'btn-success color-white' : 'btn-default' "
									@click="item.pg_status = 1;"
								>mdi mdi-power-plug</v-icon>
								<v-icon
									class="pa-5  "
									:class="item.pg_status != 1 ? 'btn-danger color-white' : 'btn-default' "
									@click="item.pg_status = 0;"
								>mdi mdi-power-plug-off</v-icon>
							</td>
						</tr>
					</template>
				</tbody>
				</template>
			</table>
			<div class="text-right mt-10">
				<button
					@click="postSave"
					class="btn-inline btn-primary"
				>저장</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {Base64} from "js-base64";

	export default {
		name: 'PointItems'
		, props: ['user', 'rules']
		, data: function(){
			return {
				program: {
					name: '포인트 상품'
					,top: true
					,title: true

				}
				, items: []
				, agency_list: []
				, item_agency: {}
				, item_search: {
					seller_id: ''
				}
				, items_point: []
			}
		}
		, computed: {
			site_list: function(){
				let t = []
				let site_list = sessionStorage.getItem('site_list')
				if(site_list){
					t = JSON.parse(Base64.decode(site_list))
				}
				return t
			}
		}
		, methods: {
			getData: async function(){
				try{


					let site_list = this.site_list
					let d = []
					if(site_list.length > 0) {
						for (let i = 0; i < site_list.length; i++) {
							const result = await this.$request.init({
								method: 'get'
								, url: 'management/getPointItems'
								, data: {
									seller_id: site_list[i].site_id
									, site_info: site_list[i].site_id
								}
							})

							if (result.success) {
								d.push({
									site_id: site_list[i].site_id
									, site_name: site_list[i].site_name
									, site_shop_uid: site_list[i].site_shop_uid
									, items_point: result.data
								})
							} else {
								this.$bus.$emit('notify', { type: 'error', message: result.message })
							}
						}
					}
					this.items = d
					console.log(this.items)
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
			, postSave: async function(){
				try{

					let site_list = this.site_list

					if(site_list.length > 0) {
						for (let i = 0; i < site_list.length; i++) {
							let items_point = this.items.filter( (item) => {
								if(site_list[i].site_id == item.site_id){

									return item.items_point
								}
							})

							const result = await this.$request.init({
								method: 'post'
								, url: 'management/postPointItems'
								, data: {
									shop_uid: site_list[i].shop_uid
									, items: JSON.stringify(items_point[0].items_point)
									, site_info: site_list[i].site_id
								}
							})

							if (result.success) {
								this.$bus.$emit('notify', {type: 'success', message: result.message})
							} else {
								throw result.message
							}
						}
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
			,getAgencyList: async function(){
				try{
					const result = await this.$request.init({
						method: 'get'
						,url: 'management/getAgencyList'
						,data: {
							agency_type: 'A001003'
						}
					})

					if(result.success){
						this.agency_list = result.data.result
						this.item_agency = this.agency_list[0];
						this.item_search.seller_id = this.item_agency.agency_id
						await this.getData()
					}else{
						this.$bus.$emit('notify', { type: 'error', message: result.message })
					}
				}catch (e) {
					console.log(e)
				}
			}
			, addItem: function(){
				this.items_point.push({
					name: ''
					, per: ''
					, is_use: ''
				})
			}
		}
		, async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getData()
		}
	}
</script>